<template>
  <div class="d-flex flex-wrap">

    <!--  BACK BUTTON  -->
    <div class="col-12 d-flex justify-content-between">
      <h2>{{ $t('side_bar.variation') }}</h2>

      <button @click="$router.go(-1)" class="col-1 back__btn btn-danger float-right mb-1">{{
          $t('reusable.to_back')
        }}
      </button>
    </div>

    <!--       CATEGORY ID       -->
    <infinity-scroll-select
      class="col-6 mb-2"
      :disabled="!unChanging"
      v-model="categoryId"
      apiUrl="products.fetchCategory"
      :label="$t('product_table.category_id')"
    />

    <!--       BRAND ID       -->
    <infinity-scroll-select
      class="col-6 mb-2"
      v-model="brandId"
      :disabled="!unChanging"
      apiUrl="products.fetchBrand"
      :label="$t('product_table.brand_id')"
    />

    <!--       MODEL NUMBER       -->
    <b-col cols="12">
      <b-form-group
        :label="$t('product_table.model_name')"
        label-for="defaultLabel"
      >
        <b-form-input
          v-model="model_number"
          id="defaultLabel"
          size="lg"
          :disabled="!unChanging"
          placeholder="model-number"
        />
      </b-form-group>
    </b-col>

    <!--       SKU       -->
    <b-col cols="6">
      <b-form-group
        label="SKU"
        label-for="defaultLabel"
      >
        <b-form-input
          :disabled="!unChanging"
          v-model="sku"
          id="defaultLabel"
          size="lg"
          placeholder="sku"
        />
      </b-form-group>
    </b-col>

    <!--       DATE EOL       -->
    <b-col cols="6">
      <b-form-group
        :label="$t('product_table.date_eol')"
        label-for="defaultLabel"
      >
        <b-form-datepicker
          id="example-datepicker"
          v-model="date_eol"
          size="lg"
          class="mb-2"
          :disabled="!unChanging"
        />
      </b-form-group>
    </b-col>

    <!--    DRAG AND DROP     -->
    <b-col cols="12">

      <VueFileAgent
        ref="vueFileAgent"
        :uploadUrl="uploadUrl"
        v-model="images_list"
        :multiple="true"
        :disabled="!unChanging"
        :deletable="true"
        :accept="'image/*,.zip'"
        :maxSize="'2MB'"
        :maxFiles="15"
        :helpText="$t('reusable.file_upload_text')"
        @select="filesSelected($event)"
        @beforedelete="onBeforeDelete($event)"
        @delete="fileDeleted($event)"
      >

      </VueFileAgent>
    </b-col>

    <b-col cols="6" class="mt-1">
      <b-form-group
        :label="$t('region_table.created_at')"
        label-for="defaultLabel"
      >
        <b-form-input
          v-model="createdAt"
          id="defaultLabel"
          size="lg"
          :disabled="!editAllow"
          :placeholder="$t('region_table.created_at')"
        />
      </b-form-group>
    </b-col>

    <b-col cols="6" class="mt-1">
      <b-form-group
        :label="$t('region_table.updated_at')"
        label-for="defaultLabel"
      >
        <b-form-input
          v-model="updatedAt"
          id="defaultLabel"
          size="lg"
          :disabled="!editAllow"
          :placeholder="$t('region_table.updated_at')"
        />
      </b-form-group>
    </b-col>

    <b-card
      style="width: 100%"
      class="mb-4 mt-4"
    >

      <!--  TABLE  -->
      <b-col cols="12">
        <div class="add__variations-button float-right mb-2">

          <h4>{{ $t('reusable.variations') }}</h4>

          <!--          create-->
          <modal-button
            ripple="rgba(113, 102, 240, 0.15)"
            size="lg"
            name="modal-main"
            :modalButton="$t('reusable.modal_add_btn')"
            @btn-emit="createNewVariation"
            @open-emit="clearData"
            :backdrop-close="true"
            :modal-title="$t('product_table.add_product_title')"
            variant="success"
          >
            <template v-slot:button @click="createNewVariation">{{ $t('reusable.modal_add_btn') }}</template>

            <template v-slot:modal-body>
              <div class="d-flex flex-wrap">

                <!--                       CATEGORY ID       -->
                <infinity-scroll-select
                  class="col-6 mb-2"
                  v-model="categoryId"
                  :disabled="!unChanging"
                  apiUrl="products.fetchCategory"
                  :label="$t('product_table.category_id')"
                />

                <!--                       BRAND ID       -->
                <infinity-scroll-select
                  class="col-6 mb-2"
                  :disabled="!unChanging"
                  v-model="brandId"
                  apiUrl="products.fetchBrand"
                  :label="$t('product_table.brand_id')"
                />

                <!--                       MODEL NUMBER       -->
                <b-col cols="12">
                  <b-form-group
                    :label="$t('product_table.model_name')"
                    label-for="defaultLabel"
                  >
                    <b-form-input
                      v-model="model_number"
                      id="defaultLabel"
                      size="lg"
                      :disabled="!unChanging"
                      placeholder="model-number"
                    />
                  </b-form-group>
                </b-col>

                <!--                       SKU       -->
                <b-col cols="6">
                  <b-form-group
                    label="SKU"
                    label-for="defaultLabel"
                  >
                    <b-form-input
                      v-model="skuModal"
                      id="defaultLabel"
                      size="lg"
                      placeholder="sku"
                    />
                  </b-form-group>
                </b-col>

                <!--                       DATE EOL       -->
                <b-col cols="6">
                  <b-form-group
                    :label="$t('product_table.date_eol')"
                    label-for="defaultLabel"
                  >
                    <b-form-datepicker
                      id="example-datepicker"
                      v-model="date_eol"
                      size="lg"
                      :disabled="!unChanging"
                      class="mb-2"

                    />
                  </b-form-group>
                </b-col>

                <!--                    DRAG AND DROP     -->
                <b-col cols="12">
                  <VueFileAgent
                    ref="vueFileAgent"
                    :uploadUrl="uploadUrl"
                    v-model="imagesListModal"
                    :multiple="true"
                    :deletable="true"
                    :accept="'image/*,.zip'"
                    :maxSize="'2MB'"
                    :maxFiles="15"
                    :helpText="$t('reusable.file_upload_text')"
                    @select="filesSelected($event)"
                    @beforedelete="onBeforeDelete($event)"
                    @delete="fileDeleted($event)"
                  >

                  </VueFileAgent>
                </b-col>

                <b-col cols="12" v-if="specifications" class="d-flex flex-wrap mt-2">
                  <infinity-scroll-select-spec
                    v-for="(option) in specifications.specifications"
                    :key="option.id"
                    class="col-6 mb-2"
                    :label="checkLocales(option.name)"
                    v-model="specOption[option.id]"
                    apiUrl="products.fetchSpecId"
                    api-option="options"
                    :api-id="option.id"
                  />
                </b-col>

              </div>
            </template>
          </modal-button>
        </div>

        <b-table
          striped
          hover
          bordered
          responsive
          sort-icon-left
          :busy="isBusy"
          :items="items"
          :fields="fields"
          :sort-by.sync="sortBy"
          :sort-desc.sync="sortDesc"
          :sort-direction="sortDirection"
          :filter="filter"
          :filter-included-fields="filterOn"
          @filtered="onFiltered"
        >
          <template v-slot:head(is_deleted)="data">
            <b-form-select
              size="md"
              v-model="status"
              class="w-100"
            >
              <template #first>
                <option
                  selected
                  :value="null"
                >
                  Выберите статус
                </option>
              </template>
              <option
                v-for="(status, index) in statuses"
                :key="index"
                :value="status"
              >
                {{ status }}
              </option>
            </b-form-select>
          </template>

          <template #table-busy>
            <div class="text-center text-primary my-2">
              <b-spinner class="align-middle"></b-spinner>
              <strong class="ml-1">{{ $t('reusable.isBusy') }}</strong>
            </div>
          </template>

          <template #cell(options)="{ item }">
            <div v-for="(option, index) in getSpecifications(item.options)" :key="index">
              {{ option }}
            </div>
          </template>

          <template #cell(name)="data">
            {{ data.item.name }}
          </template>

          <template #cell(images_list)="data">
            <div style="width: 80px; height: 80px" v-if="data.item.images_list.length > 0">
              <img style="object-fit: cover; width: 100%; height: 100%" :src="data.item.images_list[0].w_500">
            </div>
          </template>

          <template #cell(crud_row)="data">
            <div class="d-flex float-right">

              <b-dropdown
                no-caret
                id="table-action-dropdown"
                variant="link"
                size="md"
              >
                <template
                  #button-content
                  class="p-0"
                >
                  <feather-icon
                    icon="MoreVerticalIcon"
                    class="p-0"
                  />
                </template>

                <!--  EDIT BUTTON  -->
                <b-dropdown-item
                  class="px-0"
                >
                  <modal-button
                    ripple="rgba(113, 102, 240, 0.15)"
                    size="lg"
                    :backdrop-close="true"
                    :name="`modal-edit-${data.item.id}`"
                    :modalButton="$t('region_table.country_edit_modal_title')"
                    :id="data.item.id"
                    :modal-title="$t('region_table.country_edit_modal_title')"
                    variant="flat-warning"
                    @open-emit="getVariationById(data.item.id)"
                    @btn-emit="updateVariation(data.item.id, data.item)"
                  >
                    <template
                      v-slot:button
                      style="width: 100% !important;"
                    >
                      <div class="d-flex align-items-center">
                        <feather-icon
                          icon="Edit2Icon"
                          size="21"
                          style="margin-right: 6px"
                        />
                        <span>{{ $t('reusable.modal_edit_btn') }}</span>
                      </div>
                    </template>

                    <template v-slot:modal-body>
                      <div class="d-flex flex-wrap">

                        <!--       SKU       -->
                        <b-col cols="12">
                          <b-form-group
                            label="SKU"
                            label-for="defaultLabel"
                          >
                            <b-form-input
                              v-model="skuModal"
                              id="defaultLabel"
                              size="lg"
                              placeholder="sku"
                            />
                          </b-form-group>
                        </b-col>

                        <b-col cols="6">
                          <b-form-group
                            :label="$t('region_table.created_at')"
                            label-for="defaultLabel"
                          >
                            <b-form-input
                              v-model="createdAt"
                              size="lg"
                              :disabled="!editAllow"
                              :placeholder="$t('region_table.created_at')"
                            />
                          </b-form-group>
                        </b-col>

                        <b-col cols="6">
                          <b-form-group
                            :label="$t('region_table.created_at')"
                            label-for="defaultLabel"
                          >
                            <b-form-input
                              v-model="updatedAt"
                              size="lg"
                              :disabled="!editAllow"
                              :placeholder="$t('region_table.created_at')"
                            />
                          </b-form-group>
                        </b-col>

                        <!--    DRAG AND DROP     -->
                        <b-col cols="12">
                          <VueFileAgent
                            ref="vueFileAgent"
                            :uploadUrl="uploadUrl"
                            v-model="imagesListModal"
                            :multiple="true"
                            :deletable="true"
                            :accept="'image/*,.zip'"
                            :maxSize="'2MB'"
                            :maxFiles="15"
                            :helpText="'Choose images or zip files'"
                            @select="filesSelected($event)"
                            @beforedelete="onBeforeDelete($event)"
                            @delete="fileDeleted($event)"
                          >
                          </VueFileAgent>
                        </b-col>

                        <b-col cols="12" v-if="specifications" class="d-flex flex-wrap mt-3">
                          <infinity-scroll-select-spec
                            v-for="(option) in specifications.specifications"
                            :key="option.id"
                            class="col-6 mb-2"
                            :label="checkLocales(option.name)"
                            v-model="specOptionModal[option.id]"
                            apiUrl="products.fetchSpecId"
                            api-option="options"
                            :api-id="option.id"
                          />
                        </b-col>

                      </div>
                    </template>

                  </modal-button>
                </b-dropdown-item>

                <!--  DELETE BUTTON  -->
                <b-dropdown-item>
                  <modal-button
                    ripple="rgba(113, 102, 240, 0.15)"
                    size="sm"
                    :name="`modal-delete-${data.item.id}`"
                    :modalButton="$t('region_table.country_delete_modal_title')"
                    :modal-title="$t('region_table.country_delete_modal_title')"
                    variant="flat-danger"
                    :id="data.item.id"
                    @btn-emit="deleteVariation"
                  >
                    <template v-slot:button class="w-100">
                      <div class="d-flex align-items-center">
                        <feather-icon
                          icon="Trash2Icon"
                          size="21"
                          style="margin-right: 6px"
                        />
                        <span>{{ $t('reusable.modal_delete_btn') }}</span>
                      </div>
                    </template>

                    <template v-slot:modal-body>
                      <div class="my-2">
                        {{ $t('product_table.delete_product_text') }}
                      </div>
                    </template>

                  </modal-button>
                </b-dropdown-item>

              </b-dropdown>

            </div>
          </template>
        </b-table>
      </b-col>

      <b-col
        cols="12"
      >
        <b-pagination
          v-if="showPagination"
          v-model="pagination.current"
          :total-rows="pagination.total"
          :per-page="pagination.per_page"
          align="center"
          size="sm"
          class="mb-5 mt-2"
        />
      </b-col>

    </b-card>


  </div>
</template>

<script>

import {
  BBadge,
  BButton,
  BCol,
  BDropdown,
  BDropdownItem,
  BFormCheckbox,
  BFormDatepicker,
  BFormFile,
  BFormGroup,
  BFormInput,
  BFormSelect,
  BInputGroup,
  BInputGroupAppend,
  BPagination,
  BRow,
  BLink,
  BTable,
  BCard,
  BSpinner
} from 'bootstrap-vue'
import ModalButton from '@/views/ui/modals/ModalButton.vue'
import api from '@/services/api'
import InfinityScrollSelect from '@/views/ui/infinity-scroll/InfinityScrollSelect'
import InfinityScrollSelectSpec from '@/views/ui/infinity-scroll/InfinityScrollSelectSpec'
import Compressor from 'compressorjs'

export default {
  name: 'Variation',
  components: {
    BTable,
    BBadge,
    BRow,
    BLink,
    BCol,
    BFormGroup,
    BFormSelect,
    BPagination,
    BInputGroup,
    BFormInput,
    BInputGroupAppend,
    BButton,
    BFormFile,
    BFormDatepicker,
    BDropdown,
    BDropdownItem,
    BFormCheckbox,
    BCard,
    BSpinner,
    ModalButton,
    InfinityScrollSelect,
    InfinityScrollSelectSpec,
  },
  data() {
    return {
      createdAt: '',
      updatedAt: '',
      unChanging: false,
      images: [],
      maxImage: 13,
      editAllow: false,
      categoryId: null,
      categoryApi: {},
      brandId: null,
      sortBy: '',
      sortDesc: false,
      sortDirection: 'asc',
      filter: null,
      filterOn: [],
      status: null,
      infoModal: {
        id: 'info-modal',
        title: '',
        content: '',
      },
      statuses: [true, false],
      fields: [
        {
          key: 'id',
          label: 'ID',
        },
        {
          key: 'images_list',
          label: this.$t('product_table.image_list'),
        },
        {
          key: 'sku',
          label: this.$t('product_table.sku'),
        },
        {
          key: 'options',
          label: this.$t('spec_table.name'),
          sortable: true,
        },
        {
          key: 'created_at',
          label: this.$t('region_table.created_at'),
        },
        {
          key: 'updated_at',
          label: this.$t('region_table.updated_at'),
        },
        {
          key: 'crud_row',
          label: ' ',
        },
      ],
      variant: '',
      items: [],
      model_number: '',
      sku: '',
      skuModal: '',
      date_eol: '',
      images_list: [],
      imagesListModal: [],
      isBusy: false,
      image: [],
      pagination: {
        current: 1,
        total: 1,
        per_page: 5
      },
      fileRecords: [],
      uploadUrl: '',
      uploadHeaders: { 'X-Test-Header': 'vue-file-agent' },
      fileRecordsForUpload: [],
      itemId: null,
      specifications: null,
      specOption: [],
      specOptionModal: [],
    }
  },
  watch: {
    '$route.query': {
      handler(query) {
        this.pagination.current = query.page
        this.fetchVariationList()
      },
      deep: true,
      immediate: true
    },
    'pagination.current': {
      handler(page) {
        this.replaceRouter({
          ...this.query,
          page,
        })
      },
    },
    categoryId(value) {
      if (value) {
        this.getCategoryById(value.id)
      } else {
        this.specifications = {}
      }
    }
  },

  async mounted() {
    await this.getProductById()
  },

  computed: {
    rows() {
      return this.items.length
    },

    query() {
      return Object.assign({}, this.$route.query)
    },

    hasItems() {
      return this.items.length > 0
    },

    showPagination() {
      return this.hasItems && !this.isBusy
    },

    sortOptions() {
      // Create an options list from our fields
      return this.fields
        .filter(f => f.sortable)
        .map(f => ({
          text: f.label,
          value: f.key,
        }))
    },
  },

  methods: {

    deleteUploadedFile: function (id, fileRecord) {
      api.products.deleteVariationFile(this.itemId, this.imagesListModal.indexOf(fileRecord))
      this.$refs.vueFileAgent.deleteUpload(this.uploadUrl, this.uploadHeaders, fileRecord)
    },

    filesSelected: function (fileRecordsNewlySelected) {
      let validFileRecords = fileRecordsNewlySelected.filter((fileRecord) => !fileRecord.error)
      this.fileRecordsForUpload = this.fileRecordsForUpload.concat(validFileRecords)
    },

    onBeforeDelete: function (fileRecord) {
      let i = this.fileRecordsForUpload.indexOf(fileRecord)
      if (i !== -1) {
        this.fileRecordsForUpload.splice(i, 1)
        this.deleteUploadedFile(fileRecord)
      } else {
        this.$refs.vueFileAgent.deleteFileRecord(fileRecord)
      }
    },

    async fileDeleted(fileRecord) {
      const deleteFileRecord = this.imagesListModal.findIndex(image => image.url === fileRecord.url)
      await api.products.deleteVariationFile(this.itemId, deleteFileRecord)
      await this.$refs.vueFileAgent.deleteUpload(this.uploadUrl, this.uploadHeaders, fileRecord)
      let i = this.fileRecordsForUpload.indexOf(fileRecord)
      if (i !== -1) {
        this.fileRecordsForUpload.splice(i, 1)
      }
    },

    replaceRouter(query) {
      this.$router.replace({ query })
        .catch(() => {
        })
    },
    srcToFile(src, fileName, mimeType) {
      const token = localStorage.getItem('accessToken')
      return (fetch(src, {
            headers: {
              'Accept': 'application/json',
              'Content-Type': 'application/json',
              'Authorization': token
            }
          }
        )
          .then(function (res) {
            return res.arrayBuffer()
          })
          .then(function (buf) {
            return new File([buf], fileName, { type: mimeType })
          })
      )
    },

    async getProductById() {
      this.itemId = id
      this.images_list = []
      const data = {
        with: {
          0: 'category',
          1: 'brand',
        }
      }
      const id = this.$route.params.id
      this.editAllow = false
      await api.products.fetchOneProduct(id, data)
        .then(res => {
          this.createdAt = res.data.created_at
          this.updatedAt = res.data.updated_at
          this.categoryId = res.data.category
          this.brandId = res.data.brand
          this.model_number = res.data.model_number
          this.sku = res.data.sku
          this.date_eol = res.data.date_eol
          const [day, month, year] = this.date_eol.split('.')
          this.date_eol = [year, month, day].join('-')

          for (let i = 0; i < res.data.images_list.length; i++) {
            const image = res.data.images_list[i].original
            this.images_list = [...this.images_list, {
              name: 'image.jpg',
              size: 0,
              type: 'image/jpg',
              url: image,
              src: image
            }]
          }

        })
        .catch((error) => {
          console.error(error)
        })
        .finally(() => {

        })
    },

    async getCategoryById(id) {
      const data = {
        with: {
          0: 'specifications.options'
        }
      }
      // this.editAllow = false
      await api.products.fetchOneCategory(id, data)
        .then(res => {
          this.specifications = res.data
        })
        .catch((error) => {
          console.error(error)
        })
        .finally(() => {

        })
    },

    async fetchVariationList() {
      this.isBusy = true
      const { id } = this.$route.params
      const data = {
        with: {
          0: 'options',
        },
        filter: {
          product_id: id
        },
        page: this.$route.query.page
      }

      await api.products.fetchVariation(data)
        .then(res => {
          this.items = res.data.data
          this.pagination.per_page = res.data.meta.per_page
          this.pagination.total = res.data.meta.total
          this.pagination.current = res.data.meta.current_page
        })
        .catch((error) => {
          console.error(error)
        })
        .finally(() => {
          this.isBusy = false
        })
    },

    async getVariationById(id) {
      this.clearData()
      this.itemId = id
      const data = {
        with: {
          0: 'options'
        }
      }
      this.editAllow = false
      await api.products.fetchOneVariation(id, data)
        .then(res => {
          // console.log(res,'res id')
          this.createdAt = res.data.created_at
          this.updatedAt = res.data.updated_at
          // res.data.options.forEach(({
          //   specification_id,
          //   id,
          //   name
          // }) => {
          //   this.specOptionModal = [...{
          //     name: this.checkLocales(name),
          //     id,
          //     specification_id,
          //   }]
          // })
          console.log(res.data.options, 'res.data.options')
          // res.data.options.forEach((item) => {
          //   console.log(item, 'item')
          //   this.specOptionModal.push(item)
          // })

          this.skuModal = res.data.sku
          this.specOption = res.data.options
          this.specOptionModal = res.data.options

          for (let i = 0; i < res.data.images_list.length; i++) {
            const image = res.data.images_list[i].original
            this.imagesListModal = [...this.imagesListModal, {
              name: 'image.jpg',
              size: 10,
              type: 'image/jpg',
              url: image,
              src: image
            }]
          }

        })
        .catch((error) => {
          console.error(error)
        })
        .finally(() => {

        })
    },

    createNewVariation() {
      const optionIds = []
      const formData = new FormData()
      formData.append('product_id', this.$route.params.id)
      formData.append('sku', this.skuModal)

      for (let value of Object.values(this.specOption)) {
        optionIds.push(value.id)
      }

      for (let i = 0; i < this.imagesListModal.length; i++) {
        formData.append('images_list[' + i + ']', this.imagesListModal[i].file)
      }

      for (let i = 0; i < optionIds.length; i++) {
        formData.append('options[' + i + ']', optionIds[i])
      }

      api.products.createVariation(formData)
        .then(res => {
          // console.log(res)
        })
        .catch((error) => {
          console.error(error)
        })
        .finally(() => {
          this.fetchVariationList()
        })
    },

    updateVariation() {
      const optionIds = []
      const formData = new FormData()
      formData.append('product_id', this.$route.params.id)
      formData.append('sku', this.skuModal)

      for (let value of Object.values(this.specOption)) {
        optionIds.push(value.id)
      }

      for (let i = 0; i < this.imagesListModal.length; i++) {
        if (this.imagesListModal[i].file && this.imagesListModal[i].file.webkitRelativePath == '') {
          formData.append('images_list[' + i + ']', this.imagesListModal[i].file)
        }
      }

      for (let i = 0; i < optionIds.length; i++) {
        formData.append('options[' + i + ']', optionIds[i])
      }

      api.products.updateVariation(this.itemId, formData)
        .then(res => {
          console.log(res)
        })
        .catch((error) => {
          console.error(error)
        })
        .finally(() => {
          this.fetchVariationList()
        })
    },

    deleteVariation(id) {
      api.products.deleteVariation(id)
        .then(res => {
        })
        .catch((error) => {
          console.error(error)
        })
        .finally(() => {
          this.fetchVariationList()
        })
    },

    compressImage(file) {
      return new Compressor(file, {
        maxWidth: 1200,
        quality: 0.8,
        convertSize: 5000000,
        success: (result) => {
          return new File([result], result.name, {
            type: result.type,
            lastModified: Date.now(),
          })
        }
      })
    },

    clearData() {
      this.imagesListModal = []
      this.skuModal = ''
      this.specOption = {}
    },

    checkLocales(value) {
      if (localStorage.lang === 'ru') {
        return value.ru
      }
      return value.uz
    },

    info(item, index, button) {
      this.infoModal.title = `Row index: ${index}`
      this.infoModal.content = JSON.stringify(item, null, 2)
      this.$root.$emit('bv::show::modal', this.infoModal.id, button)
    },

    resetInfoModal() {
      this.infoModal.title = ''
      this.infoModal.content = ''
    },

    onFiltered(filteredItems) {
      // Trigger pagination to update the number of modals/pages due to filtering
      this.totalRows = filteredItems.length
      this.currentPage = 1
    },

    getSpecifications(options) {
      let specNames = []
      if (!options.length) return ''
      options.forEach((option) => {
        specNames.push(option.name[localStorage.lang])
      })
      return specNames
    },
  }

}
</script>

<style lang="scss">

.back__btn {
  border: none;
  padding: 10px 21px;
  border-radius: 0.358rem;
}


[dir] .dropdown-item {
  padding: 0;
}

.image-container {
  width: 100% !important;
  background: #fff !important;
}

body.dark-layout .image-container {
  background: #283046 !important;
}

.image-list-container {
  max-width: 100% !important;
}

.image-list-item {
  width: 50px !important;
  height: 50px !important;
}

.image-list-container .image-list-item .show-img {
  max-width: 40px !important;
  max-height: 40px !important;
}

#my-strictly-unique-vue-upload-multiple-image {
  font-family: 'Avenir', Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;
}

.image-bottom-left {
  display: none !important;
}

body.dark-layout .image-icon-edit {
  width: 24px !important;
  height: 24px !important;
  fill: #fff !important;
}

body.dark-layout .image-icon-delete {
  width: 24px !important;
  height: 24px !important;
  fill: #fff !important;
}

body.dark-layout .add-image-svg {
  width: 24px !important;
  height: 24px !important;
  fill: #fff !important;
}

.image-icon-edit {
  width: 24px !important;
  height: 24px !important;
  fill: #000 !important;
}

.image-icon-delete {
  width: 24px !important;
  height: 24px !important;
  fill: #000 !important;
}

.add-image-svg {
  width: 24px !important;
  height: 24px !important;
  fill: #000 !important;
}

#example-datepicker__dialog_ {
  z-index: 1000;
}

.variables__button {
  width: 100%;
  padding: 0.786rem 1.5rem !important;
  border: none;
  background: transparent;
  color: green;
  font-weight: 500;
  text-align: left;
}

.add__variations-button {
  margin-bottom: 2rem;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.add__variations-button button {
  z-index: 2;
}

</style>
